<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import 'jspdf-autotable'
import { sumdate, sumdatetime } from '@/plugins/filters'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const fontTitle = ref(20)
    const fontBody = ref(18)
    const frame = ref('')

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    prints.printsAppointment({
      appoint_id_pri: route.value.query.id,
      lang: i18n.locale,
    }).then(res => {
      savePDF(res)
    })

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a5',
      })
      doc.setProperties({
        title: 'ใบนัดหมาย',
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'normal')
      doc.setFontSize(fontBody.value)

      const spacer = 8
      let total = 12

      const ImageToLoad = new Image()
      ImageToLoad.src = JSON.parse(localStorage.getItem('shopImage'))
      doc.addImage(ImageToLoad, 'JPEG', 10, 6, 28, 28)

      doc.setFontSize(fontTitle.value)
      doc.setFont('TH-Niramit', 'bold')
      doc.text(`${i18n.t('appointment_document')}`, 40, total)
      doc.setFontSize(fontBody.value)
      total += spacer

      doc.text(`${i18n.t('clinic')} ${data.shop_name} ${data.shop_nature}`, 40, total)
      total += spacer
      const text = `${data.shop_address} ${data.shop_district}  ${data.shop_amphoe} ${data.shop_province} ${data.shop_zipcode}`
      const text1 = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(text, 165)
      for (let x = 0; x < text1.length; x++) {
        let positionStarter = 0
        positionStarter = 40
        doc.text(positionStarter, total, text1[x])
        total += spacer
      }
      doc.text(`${i18n.t('tel')} ${data.shop_tel} ${i18n.t('email')} ${data.shop_email}`, 40, total)
      total += spacer
      doc.setLineWidth(0.5)
      doc.setDrawColor('#1E88E5')
      doc.line(61, total, 205, total)
      doc.setDrawColor('#212121')
      doc.line(4, total, 60, total)
      total += spacer
      const arr1 = [
        'HN',
        i18n.t('customer_patient'),
        i18n.t('tel'),
        i18n.t('appointment'),
        i18n.t('appointment_date'),
        i18n.t('time'),
        i18n.t('note'),
      ]
      const arr2 = [
        data.customer_id,
        data.customer_fullname,
        data.appoint_tel,
        data.user_fullname,
        sumdate(data.appoint_date),
        data.appoint_start,
        data.appoint_note,
      ]
      arr1.forEach((i, index) => {
        if (i == i18n.t('time')) {
          doc.setFont('TH-Niramit', 'bold')
          doc.text(i, 130, total - spacer)
          doc.setFont('TH-Niramit', 'normal')
          doc.text(arr2[index], 150, total - spacer)
        } else {
          doc.setFont('TH-Niramit', 'bold')
          doc.text(i, 40, total)
          doc.setFont('TH-Niramit', 'normal')
          doc.text(arr2[index], 80, total)
          total += spacer
        }
      })
      total += spacer
      doc.setFont('TH-Niramit', 'normal')
      doc.setLineWidth(0.5)
      doc.setDrawColor('#212121')
      doc.line(61, total, 205, total)
      doc.setDrawColor('#1E88E5')
      doc.line(4, total, 60, total)
      total += spacer * 2
      doc.text(`${i18n.t('date_of_appointment')} ${sumdatetime(data.appoint_create)}`, 52.5, total, null, null, 'center')
      doc.text(`${i18n.t('print_date')} ${sumdatetime(data.print_date)}`, 157.5, total, null, null, 'center')
      total += spacer

      frame.value = doc.output('datauristring', { filename: 'hello world' })
    }
    const go = () => {
      window.open(frame.value, '_blank')
    }

    return { savePDF, frame, go }
  },
}
</script>
